import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers';
import Home from './components/Home/Home'
import Faq from './components/Faq/Faq'
import Mint from './components/Mint/Mint'
import Dream from './components/Dream/Dream'

import AOS from "aos";
import "aos/dist/aos.css";


AOS.init();

function getLibrary(provider, connector) {
  return new Web3Provider(provider) // this will vary according to whether you use e.g. ethers or web3.js
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/faq" exact element={<Faq/>} />
        <Route path="/mint" exact element={<Mint/>} />
        <Route path="/dream" exact element={<Dream/>} />
      </Routes>
    </Web3ReactProvider>
  );
}

export default App;

