/* eslint-disable */
import React from "react";
import { InjectedConnector } from '@web3-react/injected-connector';
// import { Helmet } from "react-helmet";
import { useState } from "react";
import { useEagerConnect } from '../../hooks/useEagerConnect'
import { truncateAccount } from "../../utils/format.js";
import { useContract } from "../../hooks/useContract.js";
import { useReadContract } from "../../hooks/useRead.js";
import { useCsvWhitelist } from "../../hooks/useCsvWhitelist.js";
import { usePublicMint, useIsWhitelist, useWhitelistMint } from "../../hooks/useMint.js";
import { formatUnits, formatEther } from "ethers/lib/utils";

import walletimg1 from "../../images/wallet-img.png"
import navback from "../../images/back-img.png"
import menulogo from "../../images/menu-logo.svg"
import walletimg2 from "../../images/wallet-icon.png"
import walletimg3 from "../../images/wallet-icon2.png"

import mintimg1 from "../../images/mint-img.gif"

const Wallet = (props) => {
    window.onload = function(){
        //hide the preloader
        document.querySelector(".preloader").style.display = "none";
    }

    const [mintNumber, setMintNumber] = useState(1)
    const { account, activate } = useEagerConnect();
    const contract = useContract();

    const { currentSupply, mintPrice, wave, waveMaxSupply,  freeStarted, publicStarted, whitelistStarted,maxPublicMint, error: readError } = useReadContract();

    const { wl, error: wlError } = useCsvWhitelist();

    const {pending, receipt, error: txError, mint} = usePublicMint()
    const {pending: wlPending, receipt: wlReceipt, error: wlTxError, mintWhitelist} = useWhitelistMint()

    const { status, isWhitelist } = useIsWhitelist();
    const hasMetamask =
        typeof window !== 'undefined' &&
        !!window.ethereum &&
        !!window.ethereum.isMetaMask;

    const handleMetamaskClick = async () => {
      if (!account) {
        if (hasMetamask) {
          const injected = new InjectedConnector({
            supportedChainIds: [1, 4],
          });
          await activate(injected);
        } else {
          window.open('https://metamask.io/', '_blank');
        }
      }
    };

  const handleMinusMint = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber-1);
      return;
    }
  }

  const handlePlusMint = () => {
    if (publicStarted) {
      if (mintNumber < maxPublicMint) {
        setMintNumber(mintNumber+1);
        return;
      }
    }
    if (whitelistStarted || freeStarted) {
      if (wl && mintNumber < wl.max_count) {
        setMintNumber(mintNumber+1);
        return;
      }
    }
  }

  const handleMint = async () => {
    if (publicStarted) {
      await mint(mintNumber, mintPrice);
    }

    if (whitelistStarted || freeStarted) {
      if (status) {
        await mintWhitelist(mintNumber, mintPrice);
      }
    }
  }

  const renderAlert = () => {
    let alertClass, alertMsg;
    if (pending || wlPending) {
      alertClass = "alert-info"
      alertMsg = `Minting transaction submitted and will be confirmed shortly...`
    }
    if (txError || wlTxError) {
      alertClass = "alert-danger"
      if (txError) {
        alertMsg = `Error: ${txError.message}`
      } else {
        alertMsg = `Error: ${wlTxError.message}`
      }
    }
    if (receipt || wlReceipt) {
      if ((receipt && receipt.status == 1) || (wlReceipt && wlReceipt.status == 1)) {
        alertClass = "alert-success"
        alertMsg = `You have successfully minted ${mintNumber} NFTs.`
      } else {
        alertClass = "alert-danger"
        alertMsg = `Error: You minting transaction failed`
      }
    }

    return(
      <div className={`alert ${alertClass}`}>
        {alertMsg}
      </div>
    )
  }

  const renderMintButton = () => {
    if (!publicStarted && !freeStarted && !whitelistStarted) {
      return "Closed"
    }
    if (publicStarted) {
      if (pending) {
        return "Minting in progress.."
      }
      return "Mint"
    }
    if (whitelistStarted || freeStarted) {
      if (status) {
        if (wlPending) {
          return "Minting in progress.."
        }
        return "Mint"
      }
      return "Your wallet is not whitelisted."
    }
  }

    return (
<div>
<div className="preloader">
        <img src={menulogo} alt="menu-logo" />
        <div className="bar-sec"></div>
    </div>
    <div className="wallet-wrap">
    <a href="#" className="wallet-but" onClick={handleMetamaskClick}>{account? truncateAccount(account): "Connect Wallet"}</a>

        <img className="main-img" src={walletimg1}/>
        <div className="sec1">
        {(pending || txError || receipt || wlPending || wlTxError || wlReceipt) ? renderAlert() : <div />}
            <div className="sec3">
      <h4>Wave { formatUnits(wave, 0)}:
      <span>
        {
          publicStarted ? "Public Mint" :
            whitelistStarted ? "Whitelist Mint" :
              freeStarted ? "Free Mint" : "Closed"
        }
      </span></h4>
            </div>
            <div className="sec2">
            <a href="/" className="nav-back-but">
            <img src={navback}/> Go back</a>
            <img src={mintimg1} alt="menu-logo" className="mint-img1" />

            <p>(Sold Out)</p>
            {/* {currentSupply ? currentSupply : "????"}/{waveMaxSupply ? waveMaxSupply : "????"})</p> */}
            <h3>Amount to mint ({mintPrice ? formatEther(mintPrice.mul(mintNumber)) : "???"} ETH)</h3>
            <div className="quantity-block">
  <button className="quantity-arrow-minus" onClick={handleMinusMint}> <i className="fas fa-chevron-left"></i> </button>
  <input className="quantity-num" type="number" value={mintNumber} />
  <button className="quantity-arrow-plus" onClick={handlePlusMint}> <i className="fas fa-chevron-right"></i> </button>
</div>
<ul>
<li><a href="#" onClick={handleMint}> {renderMintButton()}  </a></li>
</ul>
            </div>
        </div>
    </div>
</div>
    )
};

export default Wallet;
