import { useCallback, useEffect, useMemo, useState } from 'react';

import { parseUnits } from "ethers/lib/utils"

import { useReadContract } from './useRead';
import { useCsvWhitelist } from './useCsvWhitelist';
import {
  useContract,
} from './useContract';
import { useActiveWeb3React } from './useEagerConnect';

export function useIsWhitelist() {
  const { account } = useActiveWeb3React();
  const contract = useContract();

  const { wl } = useCsvWhitelist();
  const { wave } = useReadContract();

  const [state, setState] = useState({
    status: null
  })

  const isWhitelist = useCallback(
    async () => {
      if (!account || !contract) return;
      if (!wave || !wl) return false;

      try {
        const status = await contract.isWhiteList(account, parseUnits(wl.max_count, 0), parseUnits(wl.wave, 0), wl.sig);
        setState({ status });
      } catch(error) {
        console.error(error);
        setState({ status: false });
      }
    }, [account, contract, wl, wave]
  )

  // Running get() on effect
  useEffect(() => {
    isWhitelist();
  }, [isWhitelist]);

  return useMemo(
    () => ({
      ...state,
      isWhitelist,
    }),
    [state, isWhitelist],
  );
}

export function usePublicMint() {
  const [state, setState] = useState({
    pending: false,
    transaction: null,
    receipt: null,
    error: null
  });

  const { account } = useActiveWeb3React();
  const contract = useContract();

  const mint = useCallback(
    async (numberMint, mintPrice) => {
      if (!account || !contract) return;

      try {
        const transaction = await contract.mintPublic(numberMint, {value: mintPrice.mul(numberMint)});
        setState({ transaction, pending: true, error: null, receipt: null });

        const receipt = await transaction.wait();
        setState({ transaction, pending: false, error: null, receipt });
      } catch(error) {
        setState({ transaction: null, pending: false, error, receipt: null});
        console.error(error)
      }
    }, [account, contract]
  )

  return useMemo(
    () => ({
      ...state,
      mint,
    }),
    [state, mint],
  );
}

export function useWhitelistMint() {
  const { account } = useActiveWeb3React();
  const contract = useContract();

  const { wl } = useCsvWhitelist();
  const { wave } = useReadContract();

  const [state, setState] = useState({
    pending: false,
    transaction: null,
    receipt: null,
    error: null
  })

  const mintWhitelist = useCallback(
    async (mintNumber, mintPrice) => {
      if (!account || !contract) return;
      if (!wave || !wl) return;

      try {
        const transaction = await contract.mintWhitelist(mintNumber, wl.max_count, wave, wl.sig, {value: mintPrice.mul(mintNumber)});
        setState({ transaction, pending: true, error: null, receipt: null });

        const receipt = await transaction.wait();
        setState({ transaction, pending: false, error: null, receipt });
      } catch(error) {
        setState({ transaction: null, pending: false, error, receipt: null});
        console.error(error)
      }
    }, [account, contract, wl, wave]
  )

  return useMemo(
    () => ({
      ...state,
      mintWhitelist,
    }),
    [state, mintWhitelist],
  );

}
