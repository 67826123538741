import { useActiveWeb3React } from './useEagerConnect';
import { useMemo } from 'react';
import { Contract } from '@ethersproject/contracts';

import ABI from "../abis/Dreamlands.json";

const RINKEBY_ADDRESS = "0x11338c68c10675888903b3ca5c67e19a32d22cc2";
const MAINNET_ADDRESS = "0xe26F2c3547123B5FDaE0bfD52419D71BdFb0c4EF";

// account is optional
function getContract({
  account,
  library,
  address,
}) {
  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account),
  );
}

function getProviderOrSigner(
  library,
  account,
) {
  return account ? getSigner(library, account) : library;
}

function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function useContract(
  withSignerIfPossible = true,
) {
  const { library, account } = useActiveWeb3React();
  return useMemo(() => {
    if (!ABI || !library) return null;

    try {
      const address = library.provider.chainId === "0x1" ? MAINNET_ADDRESS : RINKEBY_ADDRESS;
      return getContract({
        address,
        library,
        account: withSignerIfPossible && account ? account : undefined,
      });
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [
    library,
    withSignerIfPossible,
    account,
  ]);
}
