/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useContract,
} from './useContract';
import { formatUnits, parseEther } from "ethers/lib/utils";
import { useActiveWeb3React } from './useEagerConnect';

export function useReadContract() {
  // State
  const [state, setState] = useState(
    {
      currentSupply: null,
      mintPrice: null,
      wave: 0,
      waveMaxSupply: null,
      phaseMaxSupply: 0,
      publicStarted: false,
      freeStarted: false,
      whitelistStarted: false,
      maxPublicMint: 3,
      error: null,
    }
  );

  const { account } = useActiveWeb3React();
  const contract = useContract();
  const get = useCallback(async () => {
    if (!account || !contract) return;

    try {
      const currentSupply = formatUnits(await contract.totalSupply(), 0);
      const wave = await contract.currentWave()
      const freeStarted = await contract.freeStarted();
      const publicStarted = await contract.saleStarted();
      const whitelistStarted = await contract.whitelistStarted();
      let waveMaxSupply, mintPrice, phaseMaxSupply;
      if (wave.gt(0)) {
        if (publicStarted) {
          phaseMaxSupply = formatUnits(await contract.publicMaxSupply(wave), 0);
          mintPrice = await contract.publicPrices(wave);
        } else if (whitelistStarted) {
          phaseMaxSupply = formatUnits(await contract.whitelistMaxSupply(wave), 0);
          mintPrice = await contract.whitelistPrices(wave);
        } else if (freeStarted) {
          phaseMaxSupply = formatUnits(await contract.freeMaxSupply(wave), 0);
          mintPrice = parseEther("0");
        } else {
          phaseMaxSupply = 0;
          mintPrice = parseEther("0");
        }
        waveMaxSupply = formatUnits(await contract.publicMaxSupply(wave), 0);
      }
      setState({ ...state, currentSupply, wave, freeStarted, whitelistStarted, mintPrice, waveMaxSupply, publicStarted, phaseMaxSupply });
    } catch (error) {
      setState({ ...state, error });
      console.log(error)
    }
  }, [account, contract]);

  // Running get() on effect
  useEffect(() => {
    get();
  }, [get]);

  // Returning a new version of the state
  return useMemo(
    () => ({
      ...state,
      get,
    }),
    [state, get],
  );
}
