/* eslint-disable */
import React,{useEffect} from "react";
// import { Helmet } from "react-helmet";
import $ from 'jquery';

// eslint-disable-next-line
import Header from "../Inc/Header";
// eslint-disable-next-line
import Footer from "../Inc/Footer";
import navback from "../../images/back-img.png"
import menulogo from "../../images/menu-logo.svg"


import advocateimg1 from "../../images/advocate-img1.png"
import advocateimg2 from "../../images/advocate-img5.png"
import advocateimg3 from "../../images/advocate-img6.png"
import advocateimg4 from "../../images/advocate-img3.png"
// import advocateimg5 from "../../images/advocate-img2.png"
import advocateimg6 from "../../images/advocate-img4.png"
import dreamimg1 from "../../images/advocate-img1.png"



const Dream = (props) => {
useEffect(() => {
    window.onload = function(){
    //hide the preloader
    document.querySelector(".preloader").style.display = "none";
}
window.addEventListener("load", function(){
    // ....
    $(".faq_sec").addClass("active");
});
    // setTimeout(function() {
    //     $(".faq_sec").addClass("active");
    //   }, 5000);

   
 }, []);
  return (
      <>
     
    <div> 
        <div className="preloader">
            <img src={menulogo} alt="menu-logo" /> 
            <div className="bar-sec"></div>
        </div>
        <div className="faq_sec">
            <a href="/" className="nav-back-but">
            {/* eslint-disable-next-line */}
            <img src={navback}/> Go back</a>
        </div>
        <section className="advocate-cont dream-page">
            <div className="title"  data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                <h2>
                Community   <span> Team</span>
                </h2>
                <img className="img2" src={advocateimg2} alt="img" />
                <img className="img3" src={advocateimg3} alt="img" />
                <img className="img6" src={advocateimg6} alt="img" />
            </div>
            <ul className="dream-sec">
                {/* <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>MOPIKO</h3>
                        <h4>Strategist</h4>
                        <p> Always seeing the big picture and putting the right people into the right place</p>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                    <h3>NFTPlankton</h3>
                    <h4>Community Manager</h4>
                        <p> From planktons to whales, everyone from the open sea will be covered
</p>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>MS.FBC </h3>
                        <h4>Social Media Manager</h4>
                        <p> Remember to like, follow and retweet! Nope… don’t just do it in your Dreams
</p>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>PANDA GU</h3>
                        <h4>Content Creator, Copywriting</h4>
                        <p>  Need to dream more so that my panda eyes can go away</p>
                    </div>
                </li> */}
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>BAMBII</h3>
                        <h4>Community Manager</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>LEONNAA</h3>
                        <h4>Head Moderator</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>CALVINIO</h3>
                        <h4>Event Manager</h4>
                    </div>
                </li>
                
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>XEPPY</h3>
                        <h4>Analyst</h4>
                    </div>
                </li>
                {/* <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>CRYPTONIAN</h3>
                        <h4>Lead Analyst</h4>
                    </div>
                </li> */}
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>TYLERROAR</h3>
                        <h4>Analyst</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>KYEMOANA</h3>
                        <h4>Analyst</h4>
                    </div>
                </li>
                {/* <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>ALDEN</h3>
                        <h4>Analyst</h4>
                    </div>
                </li> */}
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>PUGGY</h3>
                        <h4>Collab Manager</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>DEVILSNAREFIZZ</h3>
                        <h4>Collab Manager</h4>
                    </div>
                </li>
                
                {/* <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>NFTPlankton</h3>
                        <h4>Moderator</h4>
                    </div>
                </li> */}
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>ROSYMONSTER</h3>
                        <h4>Moderator</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>MUNX</h3>
                        <h4>Moderator</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>HKJC</h3>
                        <h4>Moderator</h4>
                    </div>
                </li>
                
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>ANYA</h3>
                        <h4>Moderator</h4>
                    </div>
                </li>
                {/* <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>ELI</h3>
                        <h4>Pixel Artist</h4>
                    </div>
                </li>
                <li>
                    <img src={dreamimg1} alt="" /> 
                    <div className="decp">
                        <h3>SUN PIXEL</h3>
                        <h4>Pixel Artist</h4>
                    </div>
                </li> */}
            </ul>
        </section>
    </div>
    
    </>
  );
};

export default Dream;
